<div class="flex justify-center items-center page-size">

  <div class="card card-side bg-neutral shadow-xl min-h-[300px] max-h-[500px] w-3/4">

    <figure class="card-image !hidden md:!flex">
      <img src="favicon/android-chrome-512x512.png" alt="HATOFF Logo" />
    </figure>

    <div class="card-body items-center justify-between relative">
      <div class="card-title text-center flex flex-col">
        <span class="title">Heroic Ascension</span>

        <span class="italic text-sm">
          The Origin of Fel Fhenor
        </span>
      </div>

      <p class="mt-3 flex items-center max-w-[600px]">
        Collect a diverse roster of heroes, each with their own unique abilities and strengths. Gather resources,
        research new technology, and build a team that can conquer the fiercest of dungeons.

        <br><br>
        Only the best heroes will return with relics in hand, bringing you one step closer to finding the origin of Fel
        Fhenor.
      </p>

      <div class="card-actions pb-5">
        @if(hasStartedGame()) {
        <button class="btn btn-primary" [routerLink]="['/game']" appAnalyticsClick="Meta:Play:Return">Play</button>
        }

        @else {
        <button class="btn btn-primary" [routerLink]="['/game/setup']" appAnalyticsClick="Meta:Play:New">Get
          Started</button>
        }
      </div>

      <div class="card-actions absolute bottom-0 left-0 right-0 p-2">
        <app-connect-buttons></app-connect-buttons>
      </div>
    </div>

  </div>

</div>

<div class="fixed bottom-0 left-0 right-0 bg-neutral h-11 flex justify-center items-center text-sm">
  <span>
    Version {{ meta.versionString() }}
  </span>

  @if(meta.versionMismatch()) {
  <button class="btn btn-info btn-xs ms-3" (click)="meta.update()" appAnalyticsClick="Meta:Update:Click">
    Update Available ({{ meta.liveVersionString() }})
  </button>
  }
</div>
